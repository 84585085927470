@import '../../styles/customMediaQueries.css';

.root {
  /* margin-top: 24px; */

  @media (--viewportMedium) {
    /* margin-top: 32px; */
  }
}

.field,
.profileAddressSave {
  margin-bottom: 24px;
  display: block;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.field {
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
}

.error {
  /* Font */
  composes: h4 from global;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.submitButton {
  /* width: auto;
  padding: 0px 64px; */
  max-width: 175px;
}

.addressStreetContainer,
.addressPostalcodeContainer,
.addressCompanyContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
}

.addressStreet {
  width: calc(66% - 6px);
}

.addressStreetNumber {
  width: calc(34% - 6px);
}

.addressPostalcode {
  width: calc(34% - 6px);
}

.addressCity {
  width: calc(66% - 6px);
}

.personOnSite,
.personPhoneNo {
  width: calc(50% - 6px);
}

.fieldError {
  line-height: 18px;
  margin-top: 4px;
  position: absolute;
}

.animated {
  animation-duration: 0.8192s;
  animation-fill-mode: backwards;
  transform-style: preserve-3d;
}

/** intro */
@keyframes intro {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(0, 0, -50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.intro {
  animation: intro 1s ease-out;
}

/** enterRight */
@keyframes enterRight {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.enterRight {
  animation-name: enterRight;
}

/** enterLeft */
@keyframes enterLeft {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.enterLeft {
  animation-name: enterLeft;
}

/** exitRight */
@keyframes exitRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: perspective(500px) translate3d(100%, 0, -100px);
  }
}

.exitRight {
  animation-name: exitRight;
}

/** exitLeft */
@keyframes exitLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: perspective(500px) translate3d(-100%, 0, -100px);
  }
}

.exitLeft {
  animation-name: exitLeft;
}

.nav {
  margin-bottom: 15px;
  text-align: center;
}

.dot {
  color: var(--marketplaceColor); 
  font-size: 36px;
  line-height: 1;
  margin: 0 15px;
  opacity: 0.4;
  text-shadow: none;
  transition: opacity 1s ease, text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.active {
  color: var(--marketplaceColor);
  opacity: 1;
  text-shadow: 0 0px 8px;
}

.buttonSegment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 15px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.nextBtn,
.backBtn {
  /* width: auto !important;
  padding: 0px 64px; */
  max-width: 175px;
}

.backBtn {
  background-color: white !important;
  color: var(--marketplaceColor) !important;
  border: 1px solid var(--marketplaceColor);
}
