@import './OrderBreakdown.module.css';

.itemImage {
  flex: 0 0 54px;
  margin-right: 10px;

  & img {
    max-width: 100%;
  }
}

.itemInfo {
  flex: 1 1 auto;
  text-align: left;

  & .additionalItemTitle,
  .additionalItemQuantity {
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }

  & .additionalItemTitle {
    font-weight: var(--fontWeightSemiBold);
    letter-spacing: normal;

    @media (--viewportMedium) {
      padding: 0;
    }
  }
}
