.root {
  display: inline-block;
  cursor: pointer;
}

.error {
  color: var(--colorFail);
  font-size: 12px;
  margin: 0;
  padding: 0;
}
