@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 5px 0 3px 0;
  }
}

.text {
  font-weight: var(--fontWeightSemiBold);
  padding: 0px;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 0px;
    margin: 0;
  }
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  /* align-self: baseline; */
  margin-right: 8px;
  & :global(svg){
    display: flex;
  }

  /* This follows line-height */
  height: 24px;
  width: 30px;
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  composes: marketplaceBodyFontStyles from global;
  line-height: 24px;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
}

.notSelectedLabel {
  color: var(--colorGrey300);
  position: relative;
  text-decoration: line-through;
}
